import { useCallback, useEffect, useRef, useState } from "react";

export interface UseVideoRecorderProps {
  videoPreviewRef: HTMLVideoElement | undefined;
  onVideoRecorded: (blob: Blob) => void;

  // Defaults to 414x736 (9:16, portrait)
  resolution?: { width: number; height: number };
  // Optioanl camera direction, defaults to "environment" (back camera)
  facingMode?: "user" | "environment";
  // Optional max recording time in seconds
  maxRecordingTime?: number;
  // Optional video bitrate in bits per second
  videoBitsPerSecond?: number;
  // Optional audio bitrate in bits per second
  audioBitsPerSecond?: number;
}

const ENCODINGS_LISTS = ["video/mp4", "video/webm"] as const;

export function useVideoRecorder(props: UseVideoRecorderProps) {
  const {
    videoPreviewRef,
    onVideoRecorded,
    resolution = { width: 414, height: 736 },
    videoBitsPerSecond,
    audioBitsPerSecond,
    facingMode = "environment",
    maxRecordingTime,
  } = props;

  const [isReady, setIsReady] = useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [recordingTime, setRecordingTime] = useState<number>();
  const recordingStartUnixMsRef = useRef<number>(0);

  const [isErrored, setIsErrored] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const streamRef = useRef<MediaStream>();
  const mediaRecorderRef = useRef<MediaRecorder>();
  const blobs = useRef<Blob[]>([]);
  const recordingIntervalRef = useRef<NodeJS.Timeout>();

  const resetRecordingTimers = useCallback(() => {
    if (recordingIntervalRef.current) {
      clearInterval(recordingIntervalRef.current);
      recordingIntervalRef.current = undefined;
    }
  }, []);

  const destroy = useCallback(async () => {
    resetRecordingTimers();

    if (!streamRef.current) {
      return;
    }

    mediaRecorderRef.current?.stop();
    mediaRecorderRef.current = undefined;

    streamRef.current?.getTracks().forEach((track) => {
      track.stop();
    });

    streamRef.current = undefined;
    setIsReady(false);
  }, [resetRecordingTimers]);

  const initialise = useCallback(async () => {
    if (streamRef.current) {
      return;
    }

    if (!videoPreviewRef) {
      return;
    }

    // Width and height are swapped when screen is in portrait mode
    const isScreenPortrait = screen.availHeight > screen.availWidth;
    let stream: MediaStream;
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: isScreenPortrait ? resolution.height : resolution.width,
          height: isScreenPortrait ? resolution.width : resolution.height,
          facingMode: { ideal: facingMode },
        },
        audio: {},
      });
    } catch (error: unknown) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      setIsErrored(true);
      setErrorMessage(`There was an error starting the camera: ${errorMessage}`);
      return;
    }

    videoPreviewRef.srcObject = stream;
    streamRef.current = stream;
    setIsReady(true);
  }, [facingMode, resolution.height, resolution.width, videoPreviewRef]);

  const stopRecording = useCallback(async () => {
    const mediaRecorder = mediaRecorderRef.current;
    if (!mediaRecorder) {
      return;
    }

    mediaRecorder.stop();
    mediaRecorderRef.current = undefined;

    resetRecordingTimers();
    setIsRecording(false);
    setRecordingTime(undefined);
  }, [resetRecordingTimers]);

  const updateTiming = useCallback(() => {
    const elapsedSeconds = Math.floor((Date.now() - recordingStartUnixMsRef.current) / 1000);
    if (elapsedSeconds < 0) {
      return;
    }

    setRecordingTime(elapsedSeconds);
    if (maxRecordingTime && elapsedSeconds >= maxRecordingTime) {
      void stopRecording();
    }
  }, [maxRecordingTime, stopRecording, recordingStartUnixMsRef, setRecordingTime]);

  const startRecording = useCallback(async () => {
    if (!streamRef.current || !isReady) {
      return;
    }

    const supportedEncoding = ENCODINGS_LISTS.find((encoding) =>
      MediaRecorder.isTypeSupported(encoding)
    );
    let mediaRecorder: MediaRecorder;
    try {
      mediaRecorder = new MediaRecorder(streamRef.current, {
        mimeType: supportedEncoding,
        videoBitsPerSecond,
        audioBitsPerSecond,
      });
    } catch (error: unknown) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      setIsErrored(true);
      setErrorMessage(`There was an error initializing the video recording: ${errorMessage}`);
      return;
    }

    mediaRecorderRef.current = mediaRecorder;
    mediaRecorder.ondataavailable = (blobEvent) => {
      blobs.current.push(blobEvent.data);
    };

    mediaRecorder.onstop = () => {
      const blob = new Blob(blobs.current, { type: supportedEncoding ?? "video/mp4" });
      blobs.current = [];

      onVideoRecorded(blob);

      resetRecordingTimers();
      setIsRecording(false);
      setRecordingTime(undefined);
    };

    mediaRecorder.onstart = () => {
      setIsRecording(true);
      setRecordingTime(0);
      recordingStartUnixMsRef.current = Date.now();

      recordingIntervalRef.current = setInterval(updateTiming, 100);
    };

    try {
      mediaRecorder.start();
    } catch (error: unknown) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      setIsErrored(true);
      setErrorMessage(`There was an error starting the video recording: ${errorMessage}`);
    }
  }, [
    isReady,
    videoBitsPerSecond,
    audioBitsPerSecond,
    onVideoRecorded,
    resetRecordingTimers,
    updateTiming,
  ]);

  useEffect(() => {
    if (videoPreviewRef) {
      void initialise();
    }

    return () => {
      void destroy();
    };
  }, [videoPreviewRef, initialise, destroy]);

  return {
    startRecording,
    stopRecording,
    isReady,
    isRecording,
    recordingTime,
    isErrored,
    errorMessage,
  };
}
