import { Box } from "@mui/material";
import { isAndroidPlatform } from "@src/appV2/lib";
import blackPixelImage from "public/assets/images/black-pixel.png";
import { useEffect, useState } from "react";

interface TimeclockVideoPreviewContentProps {
  recordedVideoUrl: string | undefined;
}

export function TimeclockVideoPreviewContent(props: TimeclockVideoPreviewContentProps) {
  const { recordedVideoUrl } = props;
  const [recordedVideoElementRef, setRecordedVideoElementRef] = useState<HTMLVideoElement>();

  const isAndroid = isAndroidPlatform();

  // Required to load the preview frame before the video plays
  useEffect(() => {
    recordedVideoElementRef?.load();
  }, [recordedVideoElementRef]);

  return (
    <Box>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        ref={(ref) => {
          setRecordedVideoElementRef(ref ?? undefined);
        }}
        controls
        playsInline
        disablePictureInPicture
        disableRemotePlayback
        preload="auto"
        // To force android to load a poster image
        src={isAndroid ? `${recordedVideoUrl}#t=0.1` : recordedVideoUrl}
        height="100%"
        style={{
          background: "black",
          maxWidth: "100%",
          maxHeight: "55vh",
          display: "block",
          aspectRatio: "9 / 16",
          margin: "auto",
          objectFit: "contain",
          borderRadius: "16px",
        }}
        // To prevent android from showing askewed play icon until video loads
        poster={isAndroid ? blackPixelImage : undefined}
        onLoadedData={(event) => {
          if (event.currentTarget.hasAttribute("poster")) {
            event.currentTarget.removeAttribute("poster");
          }
        }}
      />
    </Box>
  );
}
